
import { useState } from 'react';

import { StyleSheet, View, FlatList, Button, Image } from 'react-native';

//NativeBaseProvider
import { NativeBaseProvider, Text, Box } from 'native-base';

import { StatusBar } from 'expo-status-bar';

import GoalItem from './components/GoalItem';
import GoalInput from './components/GoalInput';

export default function App() {

  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [courseGoals, setCourseGoals] = useState([]);

  function startAddGoalHandler() {
    setModalIsVisible(true);
  }

  function endAddGoalHandler() {
    setModalIsVisible(false);
  }

  function addGoalHandler(enteredGoalText) { 
    setCourseGoals(
      currentCourseGoals => [
        ...currentCourseGoals, 
        { text: enteredGoalText, id: Math.random().toString() }
      ]);
      endAddGoalHandler();
  };

  function deleteGoalHandler(id) {
    setCourseGoals(currentCourseGoals => {
      return currentCourseGoals.filter((goal) => goal.id !== id);
    });
  }

  return (
    <>
      <StatusBar style="light" />
      <View style={styles.appContainer}>

        <Image style={styles.image} source={require('./assets/images/owain.png')} />

        <View style={styles.addGoalButton}>
          <Button style={styles.addGoalButton} title="Add New Goal" onPress={startAddGoalHandler} />
        </View>

        <GoalInput onAddGoal={addGoalHandler} visible={modalIsVisible} onCancel={endAddGoalHandler} />

        <View style={styles.goalsContainer}>
          <FlatList 
            data={courseGoals}
            renderItem={(itemData) => {
              return <GoalItem text={itemData.item.text} onDeleteItem={deleteGoalHandler} id={itemData.item.id} />;
            }}
            keyExtractor={(item, index) => {
              return item.id;
            }}
          />
        </View>

      </View>
    </>
  );

}

const styles = StyleSheet.create({
  appContainer: {
    paddingHorizontal: 16,
    paddingTop: 50,
    height: '100%',
    backgroundColor: '#311b6b',
    alignItems: 'center',
  },
  goalsContainer: {
    flex: 5,
    width: '100%'
  },
  image: {
    width: 100,
    height: 100,
    margin: 20,
    alignContent: 'center',
    borderRadius: 100
  },
  addGoalButton: {
    backgroundColor: 'black',
  }
});
